<template>
  <content-section :spacing="false">
    <div class="relative mt-2 bg-white rounded shadow">
      <section v-if="!isLoaded" class="h-96">
        <loading :active="!isLoaded" :is-full-page="false" />
      </section>

      <section class="w-full bg-white" v-else>
        <TimelineGroup :logs="logs" />
        <infinite-loading @infinite="fetchLogs" />
      </section>
    </div>
  </content-section>
</template>

<style lang="scss" scoped></style>

<script>
import { useEndpoints } from '@/composables'
import InfiniteLoading from 'vue-infinite-loading'
import TimelineGroup from '@/components/timeline-new/TimelineGroup.vue'
import ContentSection from '@/components/layout/ContentSection'

export default {
  name: 'ViewOrganizationUserLogs',
  components: {
    InfiniteLoading,
    TimelineGroup,
    ContentSection,
  },
  computed: {
    getId() {
      return this.$route.params.id
    },
    getLimit() {
      return 30
    },
    getOffset() {
      return this.logs.length
    },
    getEndpoint() {
      return (
        useEndpoints.user.operator.logs(this.getId) +
        `?is_operator=true&limit=${this.getLimit}&offset=${this.getOffset}`
      )
    },
  },
  async created() {
    await this.fetchLogs()
  },

  data() {
    return {
      isLoaded: false,
      logs: [],
    }
  },

  methods: {
    async fetchLogs($state = null) {
      await this.$http
        .get(this.getEndpoint)
        .then((res) => {
          //   console.log('res = ', res.data)
          if (this.logs.length) {
            this.logs = this.logs.concat(res.data?.data)
            // console.log('Loaded later', res.data?.data.length)
          } else {
            this.logs = res.data?.data
            // console.log('Loaded logs', this.logs.length)
            if (!this.isLoaded) this.isLoaded = true
          }

          if ($state) {
            if (res?.data?.meta?.count?.total > this.logs.length) {
              $state.loaded()
            } else {
              $state.complete()
            }
          }
        })
        .catch((err) => {
          console.log('err = ', err.response.data)
          this.isLoaded = true
        })
    },
  },
}
</script>

<style lang="scss"></style>
